@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.homepage{
    width: 100vw;
    height: 850px;
    margin-top: 50px;
    background-color: #0188e2;
    display: flex;
    color: white;
}
.homepage-title{
    margin: auto;
    margin-top: 110px;
    width: 85%;
    display: flex;
    justify-content: space-between;
}
.homepage-content{
    height: 90%;
}
.profile{
    width: 300px;
    height: 300px;
    border-radius: 150px;
    margin-top: 10%;
}
.name{
    font-size: 90px;
    margin-top: 14px;
    margin-bottom: 4px;
}
.p1{
    margin-top: 14px;
    margin-bottom: 4px;
    font-size: 35px;
}
.connect{
    font-size: 60px;
    padding-top: 12px;
    padding-left: 4px;
    cursor: pointer;
    text-decoration: none;
    color: black;
}
.homepage-photo{
    margin-top: 5%;
    width: 25%;
}
.more-info{
    background-color: white;
    border-radius: 25px;
    text-align: center;
    width: 180px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.resume-button{
    background-color: #1088a0;
    margin-right: 20px;
    color: white;
    font-size: 20px;
}
.buttons{
    width: 340px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width: 500px) {
    .homepage{
        width: 100vw;
        height: 650px;
        background-color: #0188e2;
        color: white;
        display: flex;
        flex-direction: column;
        margin-top: 220px;
        border-top-right-radius: 50px;
        border-top-left-radius: 50px;
        box-shadow: 0px 0px 20px 20px rgb(232, 232, 232);
    }
    .name{
        font-size: 35px;
    }
    .p1{
        margin-top: 14px;
        margin-bottom: 4px;
        font-size: 25px;
    }
    .homepage-title{
        margin: auto;
        display: flex;
        margin-top: 0px;
        flex-direction: column-reverse;
        text-align: center;
        justify-content: center;
    }
    .homepage-photo{
        text-align: center;
        width: 100%;
        margin-top: -100px;
    }
    .profile{
        width: 160px;
        height: 160px;
        border-radius: 100px;
    }
    .more-info{
        margin-left: auto;
        margin-right: auto;
    }
    .buttons{
        width: 80%;
        height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: auto;
    }
}
.Navigation{
    display: flex;
    justify-content: space-between;
    color: black;
}
.Navigation-options{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    color: black;
}
.navigation-option{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 18px;
    transition-duration: 0.2s;
}
.navigation-option:hover{
    border-bottom: 4px solid #12849b;
    cursor: pointer;
}

@media (max-width: 500px) {
    .nav-name{
        display: none;
    }
    .navigation-option{
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 14px;
        transition-duration: 0.2s;
        width: 26%;
        text-align: center;
    }
}


.Education{
    padding: 10px;
    width: 87%;
    margin-left: 80px;
    margin-bottom: 20px;
    max-width: 1600px;
}
.education-title{
    font-size: 34px;
    font-weight: 600;
}
.progress{
    height: 250px;
    margin-top: 65px;
    margin-bottom: 48px;
}
.icon{
    font-size: 34px;
    margin-top: 20px;
}
.education-info{
    border: 1px solid lightgray;
    box-shadow: 0px 10px 10px #cccccc;
    padding-right: 40px;
    padding-left: 40px;
    height: 100%;
    padding-top: 18px;
    padding-bottom: 28px;
    font-size: 24px;
    text-align: center;
    background-color: white;
    border-radius: 12px;
}
.classes{
    border: 1px solid lightgray;
    max-width: 200px;
    padding-right: 40px;
    padding-left: 40px;
    height: 100%;
    padding-top: 18px;
    padding-bottom: 28px;
    text-align: center;
    background-color: white;
    border-radius: 20px;
}
.course{
    border: 1px solid #cccccc;
    box-shadow: 0px 2px 2px lightgray;
    font-size: 15px;
    padding-right: 14px;
    padding-left: 14px;
    margin-top: 16px;
    max-width: 200px;
    background: white;
    border-radius: 5px;
    background-color: rgb(245, 245, 245);
}
.classes-title{
    margin-top: 8px;
    font-weight: 600;
    font-size: 20px;
}
@media (max-width: 1200px) {
    .Education{
        margin-left: 10px;
    }
    .education-info{
        margin-top: 40px;
        margin-bottom: 40px;
    }
}
.title{
    margin-bottom: 10px;
    font-size: 28px;
}
.subtitle{
    margin-top: 0px;
    font-size: 22px;
    font-weight: 100;
}
.timeline{
    height: 100%;
    background-color: inherit;
    padding: 4px;
    margin-left: 70px;
}
.experience-title{
    font-size: 34px;
    font-weight: 600;
}

@media (max-width: 800px) {
    .timeline{
        margin-left: 10px;
    }
}
.Skills{
    padding: 10px;
    width: 87%;
    margin-left: 70px;
    margin-bottom: 20px;
    max-width: 1600px;
}
.skills-title{
    font-size: 34px;
    font-weight: 600;
}
.skills1{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.languages{
    background-color: white;
    box-shadow: 0px 4px 4px #cccccc;
    padding-bottom: 14px;
    width: 45%;
    border-radius: 15px;
}
.libraries-frameworks{
    background-color: white;
    box-shadow: 0px 4px 4px #cccccc;
    padding-bottom: 14px;
    width: 45%;
    border-radius: 15px;
}
.tools{
    background-color: white;
    box-shadow: 0px 4px 4px #cccccc;
    padding-bottom: 14px;
    width: 45%;
    border-radius: 15px;
    margin-top: 40px;
}
.skill{
    border: 1px solid #cccccc;
    box-shadow: 0px 2px 2px lightgray;
    font-size: 18px;
    padding-right: 14px;
    padding-left: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    margin-right: 20px;
    max-width: 200px;
    background: white;
    border-radius: 5px;
    background-color: rgb(245, 245, 245);
}
@media (max-width: 1000px) {
    .Skills{
        margin-left: 10px;
        width: 90%;
    }
}

@media (max-width: 500px) {
    .skills1{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;
        margin-left: 0px;
        margin: auto;
    }
    .languages{
        margin: auto;
        width: 100%;
        margin-bottom: 40px;
    }
    .libraries-frameworks{
        margin: auto;
        width: 100%;
    }
    .tools{
        margin: auto;
        width: 100%;
        margin-top: 40px;
    }
}
.Projects{
    padding: 10px;
    width: 92%;
    margin-left: 70px;
    margin-bottom: 20px;
    max-width: 1600px;
}
.project-info{
    background-color: white;
    box-shadow: 0px 4px 4px #cccccc;
    width: 90%;
    max-width: 650px;
    border-radius: 15px;
    padding: 4px 28px 10px 28px;
    transition-duration: 0.5s;
    margin-bottom: 20px;
    margin-right: 10px;
}
.project-info:hover{
    box-shadow: 0 20px 20px #cccccc;
}
@media (max-width: 1000px) {
    .Projects{
        margin-left: 5px;
        width: 100%;
    }
    .project-info{
        margin-left: 0px;
    }
}

@media (max-width: 1340px) {
    .project-info{
        margin-left: 0px;
        max-width: 800px;
    }
}


.Certifications{
    padding: 10px;
    width: 90%;
    margin-left: 70px;
    margin-bottom: 20px;
    max-width: 1600px;
}
.Certification{
    background-color: white;
    box-shadow: 0px 4px 4px #cccccc;
    padding: 4px 28px 28px 28px;
    width: 300px;
    margin-top: 90px;
    transition-duration: 0.5s;
    margin-bottom: 20px;
}
.Certificate{
    margin-top: -70px;
}
.Certification:hover{
    box-shadow: 0px 20px 20px #cccccc;
}
@media (max-width: 800px) {
    .Certifications{
        margin-left: 10px;
    }
}
.contactContainer{
    margin: auto;
}
.contactInfo{
    border: 1px solid lightgray;
    display: flex;
    padding-right: 20px;
    padding-left: 18px;
    box-shadow: 0px 5px 5px #cccccc;
    width: 550px;
    margin: auto;
    justify-content: center;
    background-color: #0977c0;
    color: white;
    font-size: 18px;
}
#email{
    margin-right: 40px;
}
.contactCard{
    margin: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-top: 40px;
    border: 1px solid lightgray;
    background-color: white;
    justify-content: space-evenly;
    padding-top: 8px;
    padding-bottom: 10px;
    box-shadow: 0 4px 4px #cccccc;
    height: 500px;
    transition-duration: 0.4s;
    width: 420px;
}
.contactCard:hover{
    box-shadow: 0 20px 20px #cccccc;
}

#contactTitle{
    margin-bottom: 0px;
    margin-top: 0px;
}

.Contact{
    padding: 10px;
    width: 92%;
    margin-left: 70px;
    max-width: 1600px;
    margin-bottom: 40px;
}
.textfield1{
    width: 328px;
}
.textfield2{
    width: 328px;
}
.textfield3{
    width: 328px;
}

@media (max-width: 800px) {
    .Contact{
        margin-left: 10px;
    }
}

@media (max-width: 500px) {
    .Contact{
        width: 90%;
        margin: auto;
    }
    .contactInfo{
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 90%;
        padding-left: 10px;
    }
    .contactCard{
        padding: 10px;
        width: 90%;
    }
    .textfield1{
        width: 85%;
    }
    .textfield2{
        width: 85%;
    }
    .textfield3{
        width: 85%;
    }
}
.app {
    font-family: 'Open Sans', sans-serif;
}

.app-content{
    display: flex;
    flex-direction: column;
    margin: auto;
    background-image: linear-gradient(to bottom right, rgb(238, 236, 236), rgb(248, 248, 248));
    height: 100%;
    width: 100%;
    overflow: hidden;
    justify-content: center;
}

