.Skills{
    padding: 10px;
    width: 87%;
    margin-left: 70px;
    margin-bottom: 20px;
    max-width: 1600px;
}
.skills-title{
    font-size: 34px;
    font-weight: 600;
}
.skills1{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.languages{
    background-color: white;
    box-shadow: 0px 4px 4px #cccccc;
    padding-bottom: 14px;
    width: 45%;
    border-radius: 15px;
}
.libraries-frameworks{
    background-color: white;
    box-shadow: 0px 4px 4px #cccccc;
    padding-bottom: 14px;
    width: 45%;
    border-radius: 15px;
}
.tools{
    background-color: white;
    box-shadow: 0px 4px 4px #cccccc;
    padding-bottom: 14px;
    width: 45%;
    border-radius: 15px;
    margin-top: 40px;
}
.skill{
    border: 1px solid #cccccc;
    box-shadow: 0px 2px 2px lightgray;
    font-size: 18px;
    padding-right: 14px;
    padding-left: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    margin-right: 20px;
    max-width: 200px;
    background: white;
    border-radius: 5px;
    background-color: rgb(245, 245, 245);
}
@media (max-width: 1000px) {
    .Skills{
        margin-left: 10px;
        width: 90%;
    }
}

@media (max-width: 500px) {
    .skills1{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;
        margin-left: 0px;
        margin: auto;
    }
    .languages{
        margin: auto;
        width: 100%;
        margin-bottom: 40px;
    }
    .libraries-frameworks{
        margin: auto;
        width: 100%;
    }
    .tools{
        margin: auto;
        width: 100%;
        margin-top: 40px;
    }
}