.Certifications{
    padding: 10px;
    width: 90%;
    margin-left: 70px;
    margin-bottom: 20px;
    max-width: 1600px;
}
.Certification{
    background-color: white;
    box-shadow: 0px 4px 4px #cccccc;
    padding: 4px 28px 28px 28px;
    width: 300px;
    margin-top: 90px;
    transition-duration: 0.5s;
    margin-bottom: 20px;
}
.Certificate{
    margin-top: -70px;
}
.Certification:hover{
    box-shadow: 0px 20px 20px #cccccc;
}
@media (max-width: 800px) {
    .Certifications{
        margin-left: 10px;
    }
}